
import Sidebar from "@/components/Nav/Sidebar/Sidebar.vue";
import {computed, defineComponent, reactive, ref, toRef} from 'vue';
import {nanoid} from 'nanoid'
import DefaultButton from "@/components/Button/DefaultButton.vue";
import InputArea from "@/components/Input/InputArea.vue";
import InputField from "@/components/Input/InputField.vue";
import {store} from "@/store";
import {RatingService} from "@/services/api/rating/rating.service";

export default defineComponent({
  name: 'Faq',
  components: {Sidebar, InputArea, InputField, DefaultButton},
  props: {
    id: {type: String, default: nanoid()},
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    visible: {type: Boolean, default: false},
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['cancelDialog', 'sendDialog'],
  setup(props, {emit}) {

    const dialogTitle = toRef(props, 'title');
    const dialogDescription = toRef(props, 'description');
    const visible = toRef(props, 'visible');
    const dialogVisible = ref(visible.value);

    const showSuccess = ref(false);
    const hasError = ref(false);
    const isButtonDisabled = ref(false);

    const hasUserRatedApplication = computed(() => store.getters['custom/hasUserRatedApplication']);

    const ratingData = reactive({
      email: "",
      description: "",
    });

    function cancelDialog() {
      dialogVisible.value = false;
      emit('cancelDialog');
    }

    async function onSubmit() {
      /* eslint-disable @typescript-eslint/camelcase */
      isButtonDisabled.value = true;
      const ratingDataForRequest = {
        rater_reference: ratingData.email,
        description: ratingData.description
      };
      const ratingResponse = await RatingService.create(ratingDataForRequest);
      if (ratingResponse) {
        if (ratingResponse.status === 200) {
          hasError.value = false;
          showSuccess.value = true;
          setTimeout(function () {
            isButtonDisabled.value = false;
            cancelDialog();
          }, 5000);
        } else {
          showSuccess.value = false;
          hasError.value = true;
          isButtonDisabled.value = false;
        }
        //TODO check response
      } else {
        showSuccess.value = false;
        hasError.value = true;
        isButtonDisabled.value = false;
      }
    }

    return {
      dialogTitle,
      dialogDescription,
      dialogVisible,
      onSubmit,
      cancelDialog,
      ratingData,
      hasUserRatedApplication,
      showSuccess,
      hasError,
      isButtonDisabled,
    }
  },
  methods: {}
})

